import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { IndexPageSharedComponents } from "./index-page-shared-components";


export const IndexPageTemplate = ({
                                    image,
                                    title,
                                    heading,
                                    phone,
                                    productlink,
                                    blogheader,
                                    templateKey,
                                    bloglink,
                                    intro,
                                    pricing,
                                  }) => (
  <IndexPageSharedComponents childImageSharp={ image.childImageSharp } image={ image } s={ phone.replace(" ", "") }
                             title={ title } phone={ phone } templateKey={ templateKey } heading={ heading }
                             content={ pricing.heading } data={ pricing.plans } gridItems={ intro.blurbs }
                             productlink={ productlink } blogheader={ blogheader } bloglink={ bloglink }/>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  phone: PropTypes.string,
  productlink: PropTypes.string,
  templateKey: PropTypes.string,
  blogheader: PropTypes.string,
  bloglink: PropTypes.string,
  pricing: PropTypes.shape({
    plans: PropTypes.array,
  }),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({data}) => {
  const {frontmatter} = data.markdownRemark
  
  return (
    <Layout>
      <IndexPageTemplate
        image={ frontmatter.image }
        templateKey={ frontmatter.templateKey }
        title={ frontmatter.title }
        heading={ frontmatter.heading }
        phone={ frontmatter.phone }
        intro={ frontmatter.intro }
        productlink={ frontmatter.productlink }
        blogheader={ frontmatter.blogheader }
        bloglink={ frontmatter.bloglink }
        pricing={ frontmatter.pricing }
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplateEng {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page-en" } }) {
            frontmatter {
                templateKey
                title
                productlink
                blogheader
                bloglink
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280, maxHeight:1280, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heading
                phone
                intro {
                    blurbs {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1200, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        text
                    }
                }
                pricing {
                    description
                    heading
                    plans {
                        description
                        description2
                        plan
                        price
                        header1
                        header2
                        header3
                        header4
                    }
                }
            }
        }
    }
`
